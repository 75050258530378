import React from "react"
import { useContext } from "react"
import { Col } from "react-bootstrap"
import { LigneBilingueFlat } from "../bilingue-flat"
import { ContexteFormationFlat } from "../imprimable"
import { useTranslation } from "react-i18next"
import { LangueEnseignement_t } from "../../syllabus/syllabus-types"

export interface AnglaisFlat_t {
	modalite: boolean
	factuel: boolean
}

interface PropsLangue {
	langEns: LangueEnseignement_t
}

export const LangueFlat = ({langEns} : PropsLangue): JSX.Element => {
	const { t } = useTranslation()
	const ctxt = useContext(ContexteFormationFlat)

	// si terminal, cplDuree, anglais et modalites réclament un formulaire particulier.
	// mis en attente

	// On déduit la traduction/description en fonction de la valeur unique
	const libAnglais = (() => {
		switch (langEns) {
			case "en":
				return {
					en: "This course is taught in English",
					fr: "Cet enseignement est donné en anglais"
				}
			case "fr+en":
				return {
					en: "This course is taught in French, but can be taught in English",
					fr:
						"Cet enseignement est donné en français, " +
						"mais il peut être donné en anglais"
				}
			case "fr":
			default:
				return {
					en: "This course is taught in French",
					fr: "Cet enseignement est donné en français"
				}
		}
	})()


	return <Col xs={12} className="mx-0 mt-2 mb-1">
		<h3>{t("langueFlat.title")}</h3>
		<LigneBilingueFlat texte={ libAnglais } langue={ctxt.langue}/>
	</Col>

}
