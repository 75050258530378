import React from "react"
import { useState } from "react"
import {
	ToggleButton,
	ToggleButtonGroup,
	Card,
	Form,
	Modal,
	Row,
	Col
} from "react-bootstrap"
import { LigneBilingue } from "../utilities/bilingue"
import { EditButton, OkCancel } from "../utilities/form-utilities"
import { LangueEnseignement_t } from "./syllabus-types"

interface PropsLangue {
	header?: JSX.Element
	langEns: LangueEnseignement_t
	update?: (langue: LangueEnseignement_t) => void
}

export const Langue = ({
	header,
	langEns,
	update
}: PropsLangue): JSX.Element => {
	const [edit, setEdit] = useState(false)
	const onclickEdit = () => {
		setEdit(true)
	}

	// On déduit la traduction/description en fonction de la valeur unique
	const libAnglais = (() => {
		switch (langEns) {
			case "en":
				return {
					en: "This course is taught in English",
					fr: "Cet enseignement est donné en anglais"
				}
			case "fr+en":
				return {
					en: "This course is taught in French, but can be taught in English",
					fr:
						"Cet enseignement est donné en français, " +
						"mais il peut être donné en anglais"
				}
			case "fr":
			default:
				return {
					en: "This course is taught in French",
					fr: "Cet enseignement est donné en français"
				}
		}
	})()

	const Formulaire = () => {
		// On se base sur la valeur actuelle pour initialiser le choix local
		const [valeur, setValeur] = useState<LangueEnseignement_t>(langEns)

		const onSubmit = () => {
			setEdit(false)
			// On appelle update uniquement si la valeur a changé
			if (update && valeur !== langEns) {
				update(valeur)
			}
		}
		const onclickCancel = () => setEdit(false)

		// const handleChange = (val: string) => setValeur(val)

		const langues = [
			{ name: "Anglais", value: "en" },
			{ name: "Français, mais anglais possible", value: "fr+en" },
			{ name: "Français seulement", value: "fr" }
		]

		return (
			<>
				<h1 className="mt-2">Langue</h1>
				<Form>
					<Row className="my-3">
						<Col xs={12} className="text-center">
							<h5>
								Les enseignements de cette matière sont
								dispensés en
							</h5>
						</Col>
						<Col xs={12} className="text-center">
							<ToggleButtonGroup
								name="langue"
								type="radio"
								value={valeur}
								onChange={(val: LangueEnseignement_t) => setValeur(val)}
							>
								{langues.map((langue, idx) => (
									<ToggleButton
										key={idx}
										id={`langue-${idx}`}
										value={langue.value}
										variant="outline-primary"
									>
										{langue.name}
									</ToggleButton>
								))}
							</ToggleButtonGroup>
						</Col>
					</Row>
					<OkCancel cancel={onclickCancel} valid={onSubmit} />
				</Form>
			</>
		)
	}

	return (
		<Card className="mx-1 mb-1">
			<Card.Body className="py-2">
				<Card.Title className="text-primary mb-2">
					<EditButton onClick={onclickEdit} />
					Langue
				</Card.Title>
				<Card.Text className="mb-1">
					<LigneBilingue texte={libAnglais} />
				</Card.Text>
			</Card.Body>
			<Modal show={edit} keyboard={false} backdrop="static">
				{header}
				<Modal.Body>{edit && <Formulaire />}</Modal.Body>
			</Modal>
		</Card>
	)
}
